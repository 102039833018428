import {avm} from 'config';
import {getCookieValue} from '../Util/helper';

const HomeJunctionLicenseKey = "A91E-1E65-88AE-A051";

export function GetHomeJunctionToken(){
    return new Promise((resolve, reject) => {
        try {
            fetch(`https://slipstream.homejunction.com/ws/api/authenticate?license=${HomeJunctionLicenseKey}`)
                .then(response => response.json().then(data => {
                    if(data) resolve(data.result.token);
                    else reject (data);
                }).catch(error => reject(error)))
                .catch(errorMsg => { reject(errorMsg); });
            } catch (e) { reject(e); }
    });
}


export function GetWeatherReport(latitude, longitude, setWeatherData) {
    return fetch(`https://api.weather.gov/points/${latitude},${longitude}`)
        .then(response => { 
            if (!response.ok) {setWeatherData(null); return null;}
            return response.json();
        }).then(data => {
            if (data && data.properties && data.properties.forecast) {
                return fetch(data.properties.forecast);
            } else {
                setWeatherData(null); return null;
            }
        }).then(response => {
            if (!response || !response.ok) {setWeatherData(null); return null;}
            return response.json();
        }).then(data => { setWeatherData(data); return data;
        }).catch(error => {setWeatherData(null);return Promise.reject(error);});
}


export function searchMultiplePointsOfInterest01(latitude, longitude, circleRadius, categories, limit) {
    return new Promise((resolve, reject) => {
        try {
            GetHomeJunctionToken()
                .then(token => {
                    const categoryQueries = categories.map(category => `category[]=${category}`).join('&');
                    fetch(`https://slipstream.homejunction.com/ws/points-of-interest/search?circle=${latitude},${longitude},${circleRadius}&sortField=distance&sortOrder=asc&${categoryQueries}&pageSize=${limit}`, {
                        headers: {'HJI-Slipstream-Token': token}})
                    .then(response => response.json()).then(data =>{ if(data) { resolve(data.result['points-of-interest'])}}
                    ).catch(error => reject(error));
                }).catch(error => reject(error));
        } catch (error) {reject(error)}
    });
}


export function searchMultiplePointsOfInterest(latitude, longitude, categories, limit, circleRadius) {
    return new Promise((resolve, reject) => {
        try {
                    const requestBody = {
                        category:categories,
                        circle: {
                            "longitude": longitude,
                            "latitude": latitude,
                            "range": circleRadius
                        }
                    };
                    fetch(`https://avm-service-stage.idcrealestate.com/api/slipstream?limit=${limit}&offset=0`, {method: 'POST',headers:Object.assign( { 'Content-Type': 'application/json'},getAVMServiceHeaders()),body: JSON.stringify(requestBody)})
                    .then(response => response.json()).then(data =>{ 
                        const categorizedData = categories.reduce((acc, category) => {
                            acc[category] = [];
                            return acc;
                        }, {});
                        data.forEach(business => {
                            if (categorizedData[business.idcCategory]) {
                                categorizedData[business.idcCategory].push(business);
                            }
                        });
                        resolve(categorizedData);
                    }).catch(error => reject(error));
        } catch (error) {reject(error)}
    });
}

export function GetSection(name,type,defaultValue,isText){
    let sectionIndex = -1;
    if(avm && avm.settings &&  (sectionIndex = avm.settings.Sections?.findIndex(x=>x.Name==name))>-1 && avm.settings.Sections[sectionIndex][type])
        return isText?avm.settings.Sections[sectionIndex][type]:{__html: avm.settings.Sections[sectionIndex][type]};
    return isText?defaultValue:{__html: defaultValue};
}


export function GetBoundaries(jsonSourceUrl){
    return new Promise((resolve, reject) => {
        try {
            fetch(jsonSourceUrl)
                .then(response => response.json().then(data => {
                    if(data) resolve(data);
                    else reject (data);
                }).catch(error => reject(error)))
                .catch(errorMsg => { reject(errorMsg); });
            } catch (e) { reject(e); }
    });
}


const projectCode = getCookieValue('Tenant')
const host =  window.location.origin;
const environment = getCookieValue('TenantMode')

// export function getAVMServiceHeaders() {
//     return Object.assign({
//         "X-Tenant": projectCode && projectCode != null ? projectCode : avm.projectCode,
//     }, projectCode && projectCode === null ? {"X-TenantMode": avm.environment, "X-TenantHost": avm.host } : { "X-TenantMode": environment, "X-TenantHost": host });
// }

export function getAVMServiceHeaders() {
    return Object.assign({
        "X-Tenant": projectCode && projectCode != null ? projectCode : avm.projectCode,
    }, environment === null ? 
        {"X-TenantMode": avm.environment, "X-TenantHost": avm.host} : 
        {"X-TenantMode": environment, "X-TenantHost": host}
    );
}



// export function getAVMServiceHeaders() {
//     return Object.assign({
//         "X-Tenant": projectCode && projectCode != null ? projectCode : avm.projectCode,
//     }, {"X-TenantMode": environment !== null ? environment : avm.environment, "X-TenantHost": projectCode != null ? host : avm.host } );
// }
// export function getAVMServiceHeaders() {
//     return Object.assign({
//         "X-Tenant": avm.token && avm.token != null ? avm.token : avm.projectCode,
//     }, avm.token && avm.token != null ? {} : { "X-TenantMode": avm.environment, "X-TenantHost": avm.host });
// }

 

 