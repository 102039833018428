import { createRoot } from 'react-dom/client';
import { avm } from 'config';
import App from '../App';
import {getCookieValue} from '../Util/helper';
import '../Assets/css/area-map.css';
import '../Assets/css/areamap-theme.css';

const containers = document.querySelectorAll('[areamap-container-id]');
containers.forEach(container => {
  const root = createRoot(container);

  if (!container) {
    console.error("Container not found!");
    return;
  }

  const getAttribute = (attr, defaultValue) => {
    const value = container.getAttribute(attr);
    return value !== null ? value : defaultValue;
  };

const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; Max-Age=0; path=/;`;
}
const tenantMapKey = getCookieValue('TenantMapKey');
deleteCookie('TenantMapKey');

const containerType = getAttribute('areaMap-type');
const jsonSourceUrl = getAttribute('json-source-url') ? getAttribute('json-source-url') : '' ;
const areaMapContainerId = getAttribute('areamap-container-id') ? getAttribute('areamap-container-id') : '';
const CommunityMapContainerId = getAttribute('communitymap-container-id') ? getAttribute('communitymap-container-id') : '';
const hidestreetView = getAttribute('hide-streetview') === 'true' ? true : false;
const hidePOI = getAttribute('hide-poi') === 'true' ? true : false;
const hideDrivingDirections = getAttribute('hide-driving-directions') === 'true' ? true : false;
const showGetDirections = getAttribute('show-GetDirections') === 'true' ? true : false;
const latitude = getAttribute('areamap-latitude') || getAttribute('areamap-latitude') === '' || getAttribute('areamap-latitude') === null ? getAttribute('areamap-latitude') : 40.677575;
const longitude = getAttribute('areamap-longitude') || getAttribute('areamap-longitude') === '' || getAttribute('areamap-longitude') === null ? getAttribute('areamap-longitude'): -73.992607;
const destinationAddress = getAttribute('areamap-destination-address') || getAttribute('areamap-destination-address') === '' || getAttribute('areamap-destination-address') === null ? getAttribute('areamap-destination-address')  : `93 3rd Street #*, New York City, NY 11231`;
// const latitude = getAttribute('areamap-latitude') ? getAttribute('areamap-latitude') : 37.69762;
// const longitude = getAttribute('areamap-longitude') ? getAttribute('areamap-longitude'): -120.99;
// const destinationAddress = getAttribute('areamap-destination-address') ? getAttribute('areamap-destination-address') : `4109 Family Lane, Modesto, CA 95356`;
// const latitude = getAttribute('areamap-latitude') ? getAttribute('areamap-latitude') :38.57426;
// const longitude = getAttribute('areamap-longitude') ? getAttribute('areamap-longitude'):-121.4499;
// const destinationAddress = getAttribute('areamap-destination-address') ? getAttribute('areamap-destination-address') : `525 40th Street, Sacramento, CA 95819`;
// const latitude = getAttribute('areamap-latitude') ? getAttribute('areamap-latitude') :41.077191;
// const longitude = getAttribute('areamap-longitude') ? getAttribute('areamap-longitude'):-73.468686;
// const destinationAddress = getAttribute('areamap-destination-address') ? getAttribute('areamap-destination-address') : 'Darien, CT';
const widgetElement = document.getElementById(areaMapContainerId);
const communityElement = document.getElementById(CommunityMapContainerId);

if (widgetElement) {
  widgetElement.style.visibility = 'hidden';
}

if (communityElement) {
  communityElement.style.visibility = 'hidden';
}

  const initIntersectionObserver = () => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          observer.unobserve(container);
          if (document.visibilityState === 'visible') { 
            if (widgetElement) {
              widgetElement.style.visibility = 'visible';
            }
            if (communityElement) {
              communityElement.style.visibility = 'visible';
            }
            const apiKey = tenantMapKey !== null ? tenantMapKey : avm.mapKey;
            configureScript(apiKey, containerType, container).then(() => {
              root.render(<App tab="home" 
                jsonSourceUrl={jsonSourceUrl} 
                areaMapLatitude={latitude} 
                areaMapLongitude={longitude} 
                destinationAddress={destinationAddress} 
                areaMapContainerId={areaMapContainerId} 
                CommunityMapContainerId={CommunityMapContainerId} 
                hidePOI={hidePOI} 
                hideDrivingDirections={hideDrivingDirections}
                hidestreetView={hidestreetView}
                showGetDirections={showGetDirections}
                />);
            });
          }
        }
      });
    }, { threshold: [0.1] });
    observer.observe(container);

    return () => {
      observer.disconnect();
    };
    
  };
  initIntersectionObserver();

});


const configureScript = (apiKey, type,container ) => {
  return new Promise((resolve,reject)=>{
    const google = window.google = window.google ? window.google : {};
    if(!type || type=='report'){
      let d3Script = document.createElement("script");
      d3Script.src = "https://d3js.org/d3.v4.js";
      d3Script.async= true;
      d3Script.onload = () => { resolve(); }
      document.head.append(d3Script);  
    }else {
      resolve();
    }
      
    let subScript = document.createElement("script");
    subScript.innerHTML = 'window.onMapLoaded = function(callback){if(!callback){window._isMapLoaded = true;let mapLoadEvent = new CustomEvent(\'maploaded\');document.dispatchEvent(mapLoadEvent);}else{if(window._isMapLoaded)callback();else document.addEventListener(\'maploaded\',callback);}};function initMap(){if(window.onMapLoaded)window.onMapLoaded();}'
    document.head.append(subScript);

    if(google && google.maps && google.maps.places && google.maps.places.Autocomplete){
      window._isMapLoaded = true;
      return;
    }

    if (!document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]')) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
    script.async = true;      
    document.head.append(script);   
    } else {
      resolve();
    }
  });
};


if (module.hot) {
  module.hot.accept();
  }

