import React ,{useContext} from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AreaMap from '../Components/areaMap';
import { Context } from '../../App';
import SearchContent from '../Components/searchContent';

export default function HomePage({ containerId, hideDrivingDirections, hidePOI, hidestreetView, showGetDirections}) {

  const { destinationAddress,viewMode } = useContext(Context);


  return (
    <>
      <div className='s-w-100 areaMap-container'>
       {!showGetDirections && <AreaMap containerId={containerId} hideDrivingDirections={hideDrivingDirections} hidePOI={hidePOI} hidestreetView={hidestreetView}/> }
        {!hideDrivingDirections && destinationAddress && destinationAddress !=='' && destinationAddress !== null && <SearchContent containerId={containerId} viewMode={viewMode}  showGetDirections={showGetDirections}/>}
        <span className='f-s-1 f-w-4 p-t-16 p-b-8 c-4 s-d-b s-ta-c'>Data is provided by third-party resources and is deemed reliable but not guaranteed.</span>
        <ToastContainer />
      </div>
    </>
  )
}
