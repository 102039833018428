import React, { useState, useEffect, useCallback } from 'react'
import { searchMultiplePointsOfInterest01 } from '../../Services/area-map'
import categoryMappings01 from '../../Util/CategoryMappings01';
import { calculateDistance, convertKmToMiles } from '../../Util/helper'


export default function POI({ latitude, longitude, mapRef, viewMode, mapLoaded, fullscreen, bounds, circleRadius = 5, limit = 500 }) {

  const [matchedPOI, setMatchedPOI] = useState({});
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryArrays, setCategoryArrays] = useState({});
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [categoriesPerRow, setCategoriesPerRow] = useState(0);
  const [clickedCategories, setClickedCategories] = useState([]);
  const [placedMarkers, setPlacedMarkers] = useState({});
  const [activeCategories, setActiveCategories] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noPOIsFound, setNoPOIsFound] = useState('');


  let filteredPOI = {};

  const CategoryOrder = ['Bank', 'Restaurant', 'Hospital', 'Supermarket', 'Education', 'Bakery', 'Train Station', 'Transit Station', 'Clothing Store', 'Airport', 'Cafe', 'Church', 'Department Store', 'Convenience Store', 'Gym', 'Park', 'Shopping Mall', 'Subway Station']

  // useEffect(() => {
  //   fecthPOIAPI()
  // }, [latitude, longitude, circleRadius, limit])

  useEffect(() => {
    if (viewMode !== 'street' && !apiCalled) {
      fecthPOIAPI();
      setApiCalled(true);
    }
  }, [latitude, longitude, circleRadius, limit, viewMode, apiCalled]);



  useEffect(() => {
    renderCategoryData();
  }, [filteredCategories, renderCategoryData]);


  useEffect(() => {
    const filteredPOI = filterPOI(categoryMappings01);
    setMatchedPOI(filteredPOI);
  }, [categoryArrays, filterPOI]);


  useEffect(() => {
    const updateCategoriesPerRow = () => {
      const newCategoriesPerRow = calculateCategoriesPerRow();
      setCategoriesPerRow(newCategoriesPerRow || 0);
    };

    updateCategoriesPerRow();
    window.addEventListener('resize', updateCategoriesPerRow);

    return () => window.removeEventListener('resize', updateCategoriesPerRow);
  }, [categoryArrays, viewMode, mapLoaded]);



  const fecthPOIAPI = async () => {
    setIsLoading(true);
    try {
      const categoriesArray = Object.keys(categoryMappings01);
      const POIData = await searchMultiplePointsOfInterest01(latitude, longitude, circleRadius, categoriesArray, limit)
      let categoryPoints = {};
      for (const key in categoryMappings01) {
        const keywords = key.split('%7C');
        const categoryData = [];
        for (const item of POIData) {
          const categoryArray = item.categories;
          const primaryCategories = categoryArray.map(cat => cat.primary);
          const secondaryCategories = categoryArray.map(cat => cat.secondary);
          const tertiaryCategories = categoryArray.map(cat => cat.tertiary);

          if (keywords.length === 3) {
            if (primaryCategories.includes(keywords[0]) && secondaryCategories.includes(keywords[1]) && tertiaryCategories.includes(keywords[2])) {
              categoryData.push(item);
            }
          } else if (keywords.length === 2) {
            if (primaryCategories.includes(keywords[0]) && secondaryCategories.includes(keywords[1])) {
              categoryData.push(item);
            }
          } else if (keywords.length === 1) {
            if (primaryCategories.includes(keywords[0])) {
              categoryData.push(item);
            }
          }
        }

        if (categoryData.length > 0) {
          categoryPoints[categoryMappings01[key].label] = categoryData;
        }
      }
      if (Object.keys(categoryPoints).length === 0) {
        setNoPOIsFound('No matching points of interest found for the selected area.')
      }
      setFilteredCategories(categoryPoints);
    } catch (error) { error }
    setIsLoading(false);
  }


  const renderCategoryData = useCallback(() => {
    const newCategoryArrays = {};
    const uniqueAddresses = new Set();

    for (const category in filteredCategories) {
      const categoryData = filteredCategories[category];
      const names = [];
      const distances = [];
      const addressLatLng = [];

      categoryData.forEach(place => {
        const distance = calculateDistance(latitude, longitude, place.coordinates.latitude, place.coordinates.longitude);
        const distanceInMiles = convertKmToMiles(distance);

        const { street, city, state, zip } = place.address;
        const formattedAddress = `${street || ''}${city ? (street ? ', ' + city : city) : ''}, ${state || ''}${zip ? ', ' + zip : ''}`;

        if (!uniqueAddresses.has(formattedAddress)) {
          uniqueAddresses.add(formattedAddress);
          names.push(place.name);
          distances.push(distanceInMiles);
          addressLatLng.push({
            latitude: place.coordinates.latitude,
            longitude: place.coordinates.longitude
          });
        }
      });

      const sortedIndexes = distances.map((_, index) => index).sort((a, b) => distances[a] - distances[b]);
      const sortedNames = sortedIndexes.map(index => names[index]);
      const sortedDistances = sortedIndexes.map(index => distances[index]);
      const sortedAddresses = Array.from(uniqueAddresses);

      if (sortedNames.length > 0 && sortedDistances.length > 0) {
        newCategoryArrays[category] = { names: sortedNames, distances: sortedDistances, address: sortedAddresses, addressLatLng: addressLatLng };
      }
    }

    setCategoryArrays(newCategoryArrays);
  }, [filteredCategories, latitude, longitude]);


  const filterPOI = useCallback((categoryMappings01) => {
    Object.keys(categoryArrays).forEach((category) => {
      Object.keys(categoryMappings01).forEach((key) => {
        if (categoryMappings01[key].label === category) {
          filteredPOI[category] = {
            category: key,
            ...categoryMappings01[key],
          };
        }
      });
    });
    return filteredPOI;
  }, [categoryArrays]);


  const findPlaces = useCallback((category, iconUrl, color) => {
    if (placedMarkers[category]) {
      placedMarkers[category].forEach(marker => marker.setMap(null));
      setPlacedMarkers(prevMarkers => ({
        ...prevMarkers,
        [category]: null
      }));
      setActiveCategories(prev => prev.filter(cat => cat !== category));
    } else {
      if (categoryArrays.hasOwnProperty(category)) {
        const markers = categoryArrays[category].addressLatLng.map((place, index) => {
          const name = categoryArrays[category].names[index];
          const address = categoryArrays[category].address[index];
          return createMarker({
            latitude: place.latitude,
            longitude: place.longitude,
            name: name,
            address: address
          }, iconUrl, color);
        });
        setPlacedMarkers(prevMarkers => ({
          ...prevMarkers,
          [category]: markers
        }));
        setActiveCategories(prev => [...prev, category]);
        // mapRef.current.setZoom(18);
      }
    }

    let updatedClickedCategories;
    if (clickedCategories.includes(category)) {
      updatedClickedCategories = clickedCategories.filter(cat => cat !== category);
    } else {
      updatedClickedCategories = prev => [category, ...prev];
    }

    setClickedCategories(updatedClickedCategories);

  }, [clickedCategories, setClickedCategories, categoryArrays, createMarker, mapRef, placedMarkers]);


  const createMarker = useCallback((place, iconUrl, color) => {
    const position = {
      lat: parseFloat(place.latitude),
      lng: parseFloat(place.longitude)
    };

    const marker = new google.maps.Marker({
      position: position,
      map: mapRef.current,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 0.6,
        strokeOpacity: 0,
        scale: 10,
      },
      label: {
        text: iconUrl,
        color: 'white',
        fontFamily: 'map-icons',
      },
    });

    // if (bounds) mapRef.current.fitBounds(bounds);

    const infowindow = new google.maps.InfoWindow({
      content: `<div class="s-d-f s-f-fd-c map-pop-up">
                  <span class="f-s-2 f-w-6 m-l-8">${place.name}</span>
                  <span class="f-s-1 f-w-5 m-l-8">${place.address}</span>
                </div>`
    });
    // marker.addListener('click', function () { mapRef.current.setZoom(14); mapRef.current.setCenter(marker.getPosition()); infowindow.open(mapRef.current, marker); });
    marker.addListener('click', function () { mapRef.current.setZoom(18); mapRef.current.setCenter(marker.getPosition()); infowindow.open(mapRef.current, marker); });
    marker.addListener('mouseover', function () { infowindow.open(mapRef.current, marker); marker.setOptions({ cursor: 'pointer' }) });
    marker.addListener('mouseout', function () { infowindow.close(); });

    google.maps.event.addListener(infowindow, 'domready', function () {
      const closeBtn = document.querySelector('.gm-ui-hover-effect');
      closeBtn.style.display = 'none';
    });

    return marker;
  }, [mapRef]);


  const calculateCategoriesPerRow = () => {
    const poiContextElement = document.querySelector('.am-poi-context');
    const categoryElements = document.querySelectorAll('.map-icon');
    if (poiContextElement && categoryElements.length > 0) {
      let totalWidth = 0;
      let count = 0;
      const poiContextWidth = poiContextElement.clientWidth;

      categoryElements.forEach(categoryElement => {
        const categoryWidth = categoryElement.clientWidth + 10;

        if ((totalWidth + categoryWidth) <= poiContextWidth) {
          totalWidth += categoryWidth;
          count++;
        } else {
          return;
        }
      });
      return count;
    }
  };

  const toggleShowAllCategories = () => { setShowAllCategories(prev => !prev) };

  return (<>
    {isLoading && viewMode !== 'street' && (
      <div className={`s-d-f s-f-jc-c s-f-ai-c ${viewMode === 'pointsOfInterest' ? '' : 'p-20 poi-loader-container'}`}>
        <div className={`s-br-50 direction-loader ${viewMode === 'pointsOfInterest' ? '' : 'poi-loader'}`} />
      </div>
    )}

    {Object.keys(categoryArrays).length > 0 && <>
      {viewMode !== 'street' && mapLoaded &&
        <div className='p-12 s-br-2 icon-container'>
          <div className={`s-d-f s-f-jc-fs s-f-ai-c s-f-fw-w am-poi-context ${showAllCategories ? '' : 'poi-context-active'}`}>
            {Object.keys(matchedPOI).sort((a, b) => {
              const indexA = CategoryOrder.indexOf(a);
              const indexB = CategoryOrder.indexOf(b);
              if (indexA === -1 && indexB === -1) {
                return a.localeCompare(b);
              } else if (indexA === -1) {
                return 1;
              } else if (indexB === -1) {
                return -1;
              } else {
                return indexA - indexB;
              }
            }).map((category, index) => (
              <a aria-label={`map-icon-${category}`} key={index} className={`c-2 s-td-n map-icon ${activeCategories.includes(matchedPOI[category].label) ? 'active-poi' : ''}`} onClick={(e) => findPlaces(matchedPOI[category].label, matchedPOI[category].unicode, matchedPOI[category].color)}>
                <span className={matchedPOI[category].icon} style={{ color: matchedPOI[category].color }}></span>
                <span data-count={categoryArrays[category].names.length}>{`${matchedPOI[category].label}`}</span>
              </a>
            ))}
          </div>
          {Object.keys(matchedPOI).length > categoriesPerRow && (
            <a className='view-more' onClick={toggleShowAllCategories}><i className={`s-td-n ${showAllCategories ? 'aga-keyboard-arrow-down' : 'aga-keyboard-arrow-up'}`} style={{ fontSize: '22px' }}></i></a>
          )}
        </div>
      }
    </>}
  </>)
}
