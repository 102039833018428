
const categoryMappings = {
  'AIRPORT': {name:'airport' ,  label: 'Airport', icon: 'map-icon-airport', unicode:"\ue801", color:'#7EB8D3' },
  'BAKERY': {name:'bakery' , label: 'Bakery', icon: 'map-icon-bakery',unicode:"\ue80a" , color:'#41b9bd' },
  'BANK': {name: 'bank', label: 'Bank', icon: 'map-icon-bank',unicode:"\ue80b" , color: '#9EBC9E'},
  'CAFE': {name:'Shoppingcafe' , label: 'Cafe', icon: 'map-icon-cafe' ,unicode: "\ue818", color:'#FD785C' },
  'CHURCH': {name:'church' ,  label: 'Church', icon: 'map-icon-church',unicode:  "\ue822", color: '#CC7C66' },
  'COFFEE SHOP':{name:'coffee_shop' , label: 'Coffee Shop', icon: 'map-icon-cafe',unicode: "\ue818", color: '#e10cd1' },
  'DELI':{name:'deli' , label: 'Deli', icon: 'map-icon-food',unicode: "\ue83c", color: '#0c1ce1' },
  'GOLF':{name:'golf' , label: 'Golf', icon: 'map-icon-golf',unicode: "\ue842", color: '#0ce14d' },
  'GROCERY': {name:'grocery' , label: 'Grocery', icon: 'map-icon-convenience-store',unicode:"\ue829" , color: '#2D3047' },
  'HOSPITAL': {name:'hospital' , label: 'Hospital', icon: 'map-icon-hospital',unicode: "\ue84b", color: '#FF1744' },
  'LIBRARY': {name:'library' , label: 'Library', icon: 'map-icon-library',unicode:"\ue855" , color: '#47682C' },
  'LODGING': {name:'lodging' , label: 'Lodging', icon: 'map-icon-lodging',unicode:"\ue85a" , color:'#B5170E'  },
  'MOSQUE': {name:'mosque' , label: 'Mosque', icon: 'map-icon-mosque',unicode:"\ue85f" , color: '#3DD6D0' },
  'PARK': {name:'park' , label: 'Park', icon: 'map-icon-park',unicode:"\ue869" , color:'#3FDD4D'  },
  'PARKING': {name:'parking' , label: 'Parking', icon: 'map-icon-parking',unicode:"\ue86a" , color: '#C18264' },
  'POOL':{name:'pool' , label: 'Pool', icon: 'map-icon-swimming',unicode: "\ue897", color: '#12de18' },
  'RESTAURANT': {name:'restaurant' , label: 'Restaurant', icon: 'map-icon-restaurant',unicode: "\ue87a", color: '#441151' },
  'SALON': {name:'beauty_salon' , label: 'Salon', icon: 'map-icon-beauty-salon',unicode:"\ue80e" , color: '#B88B4A' },
  'SCHOOL': {name:'school' , label: 'School', icon: 'map-icon-school',unicode:"\ue880", color:'#CA61C3'  },
  'SHOPPING': {name:'shopping' , label: 'Shopping', icon: 'map-icon-shopping-mall' ,unicode:"\ue884" , color:'#3F7382' },
  'SPA': {name:'spa' , label: 'Spa', icon: 'map-icon-spa',unicode: "\ue88e", color: '#A23B72' },
  'SYNAGOGUE': {name:'synagogue' , label: 'Synagogue', icon: 'map-icon-synagogue' ,unicode: "\ue898", color: '#0B0C0E'},
  'TENNIS COURT':{name:'tennis_court' , label: 'Tennis Court', icon: 'map-icon-tennis',unicode: "\ue89a", color: '#dea912' },
};
export default categoryMappings;
