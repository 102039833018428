import React, { useState, useEffect, useRef } from 'react';
import { GetSection } from '../../Services/area-map';
import { generateQRCode } from '../../Util/qrcode';
import { renderStepsHTML, getDirectionSymbol, handleStepClick } from '../../Util/helper'
import { useReactToPrint } from 'react-to-print';

export default function DirectionsMap({ destinationAddress, startingAddress, routeSummary, setModalIsOpen, logoSrc }) {
  const mapRef = useRef(null);
  const canvasRef = useRef();
  const componentRef = useRef();
  const [directions, setDirections] = useState();
  const [totalDistance, setTotalDistance] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSteps, setActiveSteps] = useState([]);

  const mapOptions = {
    zoom: 20,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
  }

  const request = {
    origin: startingAddress,
    destination: destinationAddress,
    travelMode: 'DRIVING',
    provideRouteAlternatives: true,
  }

  const mapUrl = `https://www.google.com/maps/dir/${encodeURIComponent(startingAddress)}/${encodeURIComponent(destinationAddress)}?entry=ttu}`;


  useEffect(() => {
    setLoading(true);
    const canvas = canvasRef.current;
    const qrcodeImageContainer = document.getElementById(`qrcode-image-container`);
    qrcodeImageContainer.innerHTML = '';
    generateQRCode('canvasId', 'txtQRCode');

    const image = new Image();
    image.src = canvas.toDataURL();
    image.onload = () => {
      canvas.style.display = 'none';
      qrcodeImageContainer.appendChild(image);
      setLoading(false);
    };
  }, [mapUrl]);


  useEffect(() => {
    if (!loading) {
      let directionsService = new google.maps.DirectionsService();
      let directionsRenderer = new google.maps.DirectionsRenderer();
      const map = new google.maps.Map(mapRef.current, mapOptions);
      directionsRenderer.setMap(map);

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          const selectedRoute = result.routes.find(route => route.summary === routeSummary);
          const modifiedResult = { ...result, routes: [selectedRoute] };
          directionsRenderer.setDirections(modifiedResult);
          setDirections(selectedRoute.legs[0].steps);
          setTotalDistance(selectedRoute.legs[0].distance.text);
          setTotalTime(selectedRoute.legs[0].duration.text);
        } else if (status === 'ZERO_RESULTS') {
          setTransitRoute(null);
          setDirections(null);
          setTotalDistance(null);
          setTotalTime(null);
        }
      })
    }
  }, [loading]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setModalIsOpen(false),
  });

  useEffect(() => {
    const printTimeout = setTimeout(() => {
      handlePrint();
    }, 5000);
    return () => clearTimeout(printTimeout);
  }, [setModalIsOpen])
  
  // useEffect(() => {
  //   const observer = new MutationObserver(() => {
  //     if (componentRef.current && totalDistance && totalTime  && directions ) {
  //       handlePrint();
  //     }
  //   });
 
  //   if (componentRef.current) {
  //     observer.observe(componentRef.current, { childList: true, subtree: true });
  //   }
 
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [directions, totalDistance, totalTime]);

  return (
    <>
      <div id="idc-areaMap" className='directions-map-print idc-areaMap' ref={componentRef}>
        <div className='s-d-f s-f-jc-sb s-f-ai-c s-w-100 xs-b-g m-b-30 directions-map-header' style={{ height: '110px' }}>
          <div className="p-l-8 logo">
            <img src={logoSrc} alt="Logo" style={{ height: '80px' }} />
          </div>
          <div className="s-ta-r qrcode" >
            <textarea id='txtQRCode' readOnly cols="10" rows="5" value={mapUrl} style={{ display: 'none' }}>{mapUrl}</textarea>
            <canvas id='canvasId' ref={canvasRef}></canvas>
            <div className='qrcode-image-container' id='qrcode-image-container'></div>
          </div>
        </div>
        <h4 className="m-t-0 m-b-16 f-s-6 f-w-5 property-address"><span className='f-s-5 f-w-5'>Driving directions to </span>{destinationAddress}</h4>
        <div className='map-section directions-map'>
          <div id='map-container' className='m-tb-12 s-w-100 map-container'>
            <div id="map-directions" className='s-w-100 s-h-100 s-o-h' ref={mapRef}></div>
          </div>
          <div id='driving-direction' className='driving-direction'>
            {totalDistance && totalTime && (<span className='s-d-b p-tb-12 f-w-6'>{totalDistance.replace('mi', 'miles').replace('ft', 'feet')} About {totalTime}</span>)}
            <h4 className='s-d-f s-f-ai-c p-16 f-s-4 f-w-6 m-tb-12 s-b-g'><i className="m-r-4 aga-radio-button-unchecked" />{startingAddress}</h4>
            <ul>
              {directions && directions.map((step, index) => (
                <li className={`s-w-100 s-d-f s-f-jc-sb p-tb-4 ${activeSteps.includes(index) ? 'active' : ''}`} key={index} onClick={() => handleStepClick(index, setActiveSteps)}>
                  <span className='f-s-3 s-d-f s-ta-l p-r-12 substep-left'>
                    <span className={getDirectionSymbol(step.maneuver)} style={{ minWidth: '26px' }}></span>
                    <span className='f-w-6 m-r-8 serial-number'>{index + 1}.</span>
                    {renderStepsHTML(step.instructions)}
                  </span>
                  <span className='f-s-3 f-w-6 s-ta-r' xstyle={{ minWidth: '150px' }}>
                    {step.distance.text.replace('mi', 'miles').replace('ft', 'feet')}
                  </span>
                </li>))}
            </ul>
            <h4 className='s-d-f s-f-ai-c p-16 f-s-4 f-w-6 m-tb-12 s-b-g'><i className="m-r-4 aga-destination" />{destinationAddress}</h4>
          </div>
        </div>
        <footer className='f-s-3 f-w-4 c-2 m-t-16'>Map data ©2024 Google, INEGI</footer>
      </div>
    </>
  )
}