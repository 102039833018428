 const  categoryMappings01 = {
    'Professional Services%7CAccountants': {name:'accounting' , label: 'Accounting', icon: 'map-icon-accounting' ,unicode: '\ue801', color:'#2B3F56' },
    'Travel%7CAirports': {name:'airport' ,  label: 'Airport', icon: 'map-icon-airport', unicode:"\ue801", color:'#7EB8D3' },
    'Recreation%7CAmusement Parks': {name: 'amusement_park',  label: 'Amusement Park', icon: 'map-icon-amusement-park', unicode:"\ue803" ,color:'#99D5C9'},
    'Pets%7CPet Services%7CAquarium Services': {name:'aquarium' ,  label: 'Aquarium', icon: 'map-icon-aquarium',unicode: "\ue804" , color: '#800080'},
    'Arts%20%26%20Entertainment%7CAquariums': {name:'aquarium' ,  label: 'Aquarium', icon: 'map-icon-aquarium',unicode:  "\ue804", color: '#800080'},
    'Arts%20%26%20Entertainment%7CArt Galleries': {name:'art_gallery' ,  label: 'Art Gallery', icon: 'map-icon-art-gallery',unicode:"\ue806" , color:'#800000' },
    'Shopping%7CArt Galleries': {name:'art_gallery' ,  label: 'Art Gallery', icon: 'map-icon-art-gallery',unicode:"\ue806" , color:'#800000' },
    // 'Airport': {name:'atm' ,  label: 'ATM', icon: 'map-icon-atm',unicode: "\ue808", color:'#355495'  },
    'Food%7CBakeries': {name:'bakery' , label: 'Bakery', icon: 'map-icon-bakery',unicode:"\ue80a" , color:'#5E381F' },
    'Financial Services%7CBanks%20%26%20Credit Unions': {name: 'bank', label: 'Bank', icon: 'map-icon-bank',unicode:"\ue80b" , color: '#9EBC9E'},
    'Nightlife%7CBars': {name:'bar' , label: 'Bar', icon: 'map-icon-bar' ,unicode: "\ue80c", color:'#2D3047' },
    'Beauty%7CSkin Care': {name:'beauty_salon' , label: 'Beauty Salon', icon: 'map-icon-beauty-salon',unicode:"\ue80e" , color: '#B88B4A' },
    'Beauty%7CNail Salons': {name:'beauty_salon' , label: 'Beauty Salon', icon: 'map-icon-beauty-salon',unicode:"\ue80e" , color: '#B88B4A' },
    'Beauty%7CMakeup Artists': {name:'beauty_salon' , label: 'Beauty Salon', icon: 'map-icon-beauty-salon',unicode:"\ue80e" , color: '#B88B4A' },
    'Beauty%7CBarbers': {name:'beauty_salon' , label: 'Beauty Salon', icon: 'map-icon-beauty-salon',unicode:"\ue80e" , color: '#B88B4A' },
    'Beauty%7CPiercing': {name:'beauty_salon' , label: 'Beauty Salon', icon: 'map-icon-beauty-salon',unicode:"\ue80e" , color: '#B88B4A' },
    'Beauty%7CHair Removal': {name:'beauty_salon' , label: 'Beauty Salon', icon: 'map-icon-beauty-salon',unicode:"\ue80e" , color: '#B88B4A' },
    'Automotive%7CMotorcycle Dealers': {name:'bicycle_store' , label: 'Bicycle Store', icon: 'map-icon-bicycle-store',unicode:"\ue80f" , color:  '#E5BA89'},
    'Shopping%7CBooks, Mags, Music%20%26%20Video%7CBookstores': {name: 'book_store', label: 'Book Store', icon: 'map-icon-book-store',unicode: "\ue814" , color: '#E2D398' },
    'Recreation%7CBowling': {name:'bowling_alley' , label: 'Bowling Alley', icon: 'map-icon-bowling-alley',unicode:"\ue815" , color: '#A3B0CC' },
    // 'Church': {name:'bus_station' , label: 'Bus Station', icon: 'map-icon-bus-station',unicode:"\ue817" , color:'#35393C'  },
    'Restaurants%7CCafes': {name:'Shoppingcafe' , label: 'Cafe', icon: 'map-icon-cafe' ,unicode: "\ue818", color:'#FD785C' },
    'Travel%7CCampgrounds': {name:'campground' , label: 'Campground', icon: 'map-icon-campground',unicode: "\ue819", color: '#EEC170' },
    'Automotive%7CCar Dealers': {name:'car_dealer' , label: 'Car Dealer', icon: 'map-icon-car-dealer',unicode:"\ue81b" , color:'#D33F49'  },
    'Travel%7CCar Rental': {name:'car_rental', label: 'Car Rental', icon: 'map-icon-car-rental' ,unicode:"\ue81c" , color:'#005485' },
    'Automotive%7CAuto Repair': {name:'car_repair' , label: 'Car Repair', icon: 'map-icon-car-repair' ,unicode: "\ue81d", color:'#59595B' },
    'Automotive%7CCar Wash': {name:'car_wash' , label: 'Car Wash', icon: 'map-icon-car-wash',unicode:"\ue81e" , color: '#121619' },
    'Arts%20%26%20Entertainment%7CCasinos': {name:'casino' , label: 'Casino', icon: 'map-icon-casino',unicode:"\ue81f" , color: '#2D4739' },
    'Local Services%7CFuneral Services%20%26%20Cemeteries': {name:'cemetery' , label: 'Cemetery', icon: 'map-icon-cemetery',unicode:  "\ue820", color: '#197F51' },
    'Religious Organizations%7CChurches': {name:'church' ,  label: 'Church', icon: 'map-icon-church',unicode:  "\ue822", color: '#CC7C66' },
    'Public Services%20%26%20Government': {name:'city_hall' , label: 'City Hall', icon: 'map-icon-city-hall',unicode:  "\ue824", color: '#FF602B' },
    'Shopping%7CFashion%7CWomen%27s Clothing': {name:'clothing_store' , label: 'Clothing Store', icon: 'map-icon-clothing-store',unicode: "\ue827", color: '#13BC9F' },
    'Shopping%7CFashion%7CMen%27s Clothing': {name:'clothing_store' , label: 'Clothing Store', icon: 'map-icon-clothing-store',unicode: "\ue827", color: '#13BC9F' },
    'Shopping%7CFashion%7CSports Wear': {name:'clothing_store' , label: 'Clothing Store', icon: 'map-icon-clothing-store',unicode: "\ue827", color: '#13BC9F' },
    'Food%7CConvenience Stores': {name:'convenience_store' , label: 'Convenience Store', icon: 'map-icon-convenience-store',unicode:"\ue829" , color: '#2D3047' },
    'Public Services%20%26%20Government%7CCourthouses': {name:'courthouse', label: 'Courthouse', icon: 'map-icon-courthouse',unicode:"\ue82a" , color: '#FFFD82' },
    'Health%20%26%20Medical%7CDentists': {name:'dentist' , label: 'Dentist', icon: 'map-icon-dentist',unicode:"\ue82d" , color: '#FF9B71' },
    'Shopping%7CDepartment Stores': {name:'department_store' , label: 'Department Store', icon: 'map-icon-department-store',unicode:"\ue82e" , color:'#E84855'  },
    'Shopping%7CFashion%7CDepartment Stores': {name:'department_store' , label: 'Department Store', icon: 'map-icon-department-store',unicode:"\ue82e" , color: '#E84855' },
    'Health%20%26%20Medical%7CDoctors': {name:'doctor' , label: 'Doctor', icon: 'map-icon-doctor',unicode:  "\ue830", color:'#66509C'  },
    'Home Services%7CElectricians': {name:'electrician' , label: 'Electrician', icon: 'map-icon-electrician',unicode:  "\ue831", color: '#0b5474' },
    'Shopping%7CElectronics': {name:'electronics_store' , label: 'Electronics Store', icon: 'map-icon-electronics-store',unicode: "\ue832", color: '#009522' },
    'Public Services%20%26%20Government%7CEmbassy': {name:'embassy' , label: 'Embassy', icon: 'map-icon-embassy',unicode:"\ue833" , color: '#9F98BA' },
    'Public Services%20%26%20Government%7CFire Departments': {name:'fire_station' , label: 'Fire Station', icon: 'map-icon-fire-station',unicode:"\ue837" , color:'#9A7197'  },
    'Shopping%7CFlowers%20%26%20Gifts%7CFlorists': {name:'florist' , label: 'Florist', icon: 'map-icon-florist' ,unicode:"\ue83b" , color:'#006FAA' },
    'Local Services%7CFuneral Services%20%26%20Cemeteries': {name:'funeral_home' , label: 'Funeral Home', icon: 'map-icon-funeral-home',unicode:"\ue83e" , color: '#0075C4' },
    'Shopping%7CHome%20%26%20Garden%7CFurniture Stores': {name:'furniture_store' , label: 'Furniture Store', icon: 'map-icon-furniture-store',unicode: "\ue83f", color: '#EEB902' },
    'Automotive%7CGas%20%26%20Service Stations': {name:'gas_station' , label: 'Gas Station', icon: 'map-icon-gas-station',unicode: "\ue840", color:'#F45D01'  },
    'Recreation%7CFitness%20%26%20Instruction%7CGyms': {name:'gym' , label: 'Gym', icon: 'map-icon-gym',unicode: "\ue844", color: '#D65108' },
    'Beauty%7CHair Salons': {name:'hair_care' , label: 'Hair Care', icon: 'map-icon-hair-care',unicode:"\ue845" , color:'#95E06C'  },
    'Beauty%7CHair Extensions': {name:'hair_care' , label: 'Hair Care', icon: 'map-icon-hair-care',unicode:"\ue845" , color:'#95E06C'  },
    'Shopping%7CHome%20%26%20Garden%7CHardware Stores': {name:'hardware_store' , label: 'Hardware Store', icon: 'map-icon-hardware-store',unicode:"\ue847" , color:'#68B684'  },
    'Religious Organizations%7CHindu Temples': {name:'hindu_temple' , label: 'Hindu Temple', icon: 'map-icon-hindu-temple',unicode: "\ue849", color: '#094D92' },
    'Shopping%7CHome%20%26%20Garden': {name:'home_goods_store' , label: 'Home Goods Store', icon: 'map-icon-shopping-mall',unicode: "\ue884", color: '#3F7382' },
    'Health%20%26%20Medical%7CHospitals': {name:'hospital' , label: 'Hospital', icon: 'map-icon-hospital',unicode: "\ue84b", color: '#FF1744' },
    'Financial Services%7CInsurance': {name:'insurance_agency' , label: 'Insurance Agency', icon: 'map-icon-insurance-agency',unicode:  "\ue84f", color: '#D90368' },
    'Shopping%7CJewelry': {name:'jewelry_store' , label: 'Jewelry Store', icon: 'map-icon-jewelry-store',unicode: "\ue851" , color: '#F7A42F' },
    'Local Services%7CDry Cleaning%20%26%20Laundry': {name:'laundry' , label: 'Laundry', icon: 'map-icon-laundry',unicode:"\ue853" , color: '#420C14' },
    'Professional Services%7CLawyers': {name:'lawyer' , label: 'Lawyer', icon: 'map-icon-lawyer',unicode: "\ue854", color: '#0B0C0E' },
    'Public Services%20%26%20Government%7CLibraries': {name:'library' , label: 'Library', icon: 'map-icon-library',unicode:"\ue855" , color: '#47682C' },
    'Food%7CBeer%2C%20Wine%20%26%20Spirits': {name:'liquor_store' , label: 'Liquor Store', icon: 'map-icon-liquor-store' ,unicode:"\ue856" , color:'#FE4A49' },
    // 'Cafe': {name:'local_government_office' , label: 'Local Government Office', icon: 'map-icon-local-government',unicode: "\ue857", color: '#F3B61F' },
    'Home Services%7CKeys%20%26%20Locksmiths': {name:'locksmith' , label: 'Locksmith', icon: 'map-icon-locksmith',unicode:"\ue859" , color:'#95C623'  },
    'Travel%7CHotels': {name:'lodging' , label: 'Lodging', icon: 'map-icon-lodging',unicode:"\ue85a" , color:'#B5170E'  },
    'Food%7CFood Delivery Services': {name:'meal_delivery' , label: 'Meal', icon: 'map-icon-restaurant' ,unicode:"\ue87a" , color:'#35393C' },
    'Food%7CFood Delivery Services': {name:'meal_takeaway' , label: 'Meal', icon: 'map-icon-restaurant',unicode:"\ue87a" , color: '#35393C' },
    'Religious Organizations%7CMosques': {name:'mosque' , label: 'Mosque', icon: 'map-icon-mosque',unicode:"\ue85f" , color: '#3DD6D0' },
    // 'Cafe': {name:'movie_rental' , label: 'Movie Rental', icon: 'map-icon-movie-rental',unicode: "\ue861", color: '#15B097' },
    'Arts%20%26%20Entertainment%7CCinema': {name:'movie_theater' , label: 'Movie Theater', icon: 'map-icon-movie-theater',unicode:"\ue862" , color: '#CCD100' },
    // 'Cafe': {name:'moving_company' , label: 'Moving Company', icon: 'map-icon-moving-company' ,unicode:"\ue863" , color:'#602C94' },
    'Arts%20%26%20Entertainment%7CMuseums': {name:'museum' , label: 'Museum', icon: 'map-icon-museum' ,unicode:"\ue864" , color:'#442B48' },
    'Nightlife': {name:'night_club' , label: 'Night Club', icon: 'map-icon-night-club',unicode:"\ue866" , color:'#FF1654'  },
    'Home Services%7CPainters': {name:'painter' , label: 'Painter', icon: 'map-icon-painter',unicode:"\ue868" , color: '#CC2936' },
    'Recreation%7CParks': {name:'park' , label: 'Park', icon: 'map-icon-park',unicode:"\ue869" , color:'#3FDD4D'  },
    'Automotive%7CParking': {name:'parking' , label: 'Parking', icon: 'map-icon-parking',unicode:"\ue86a" , color: '#C18264' },
    'Pets%7CPet Stores': {name:'pet_store' , label: 'Pet Store', icon: 'map-icon-pet-store' ,unicode:"\ue86b" , color:'#B8675A' },
    'Shopping%7CDrugstores': {name:'pharmacy' , label: 'Pharmacy', icon: 'map-icon-pharmacy',unicode:"\ue86c" , color: '#B54C50' },
    'Health%20%26%20Medical%7CPhysical Therapy': {name:'physiotherapist' , label: 'Physiotherapist', icon: 'map-icon-physiotherapist',unicode: "\ue86d", color:'#993C49'  },
    'Home Services%7CPlumbing': {name:'plumber' , label: 'Plumber', icon: 'map-icon-plumber',unicode: "\ue870", color: '#CC0C0C' },
    'Public Services%20%26%20Government%7CPolice Departments': {name:'police' , label: 'Police', icon: 'map-icon-police',unicode:"\ue872" , color: '#F9564F' },
    'Public Services%20%26%20Government%7CPost Offices': {name:'post_office' , label: 'Post Office', icon: 'map-icon-post-office',unicode:"\ue875" , color: '#E87EA1' },
    'Real Estate%7CReal Estate Services"': {name:'real_estate_agency' , label: 'Real Estate Agency', icon: 'map-icon-real-estate-agency',unicode:"\ue879" , color: '#FF8A5B' },
    'Restaurants': {name:'restaurant' , label: 'Restaurant', icon: 'map-icon-restaurant',unicode: "\ue87a", color: '#441151' },
    'Home Services%7CRoofing': {name:'roofing_contractor' , label: 'Roofing Contractor', icon: 'map-icon-roofing-contractor',unicode:"\ue87b", color:'#822E81'  },
    'Travel&RV Parks': {name:'rv_park' , label: 'RV Park', icon: 'map-icon-rv-park',unicode:"\ue87e" , color:'#7C77B9'  },
    'Education': {name:'school' , label: 'Education', icon: 'map-icon-school',unicode:"\ue880", color:'#CA61C3'  },
    'Shopping%7CShopping Centers': {name:'shopping_mall' , label: 'Shopping Mall', icon: 'map-icon-shopping-mall' ,unicode:"\ue884" , color:'#3F7382' },
    'Beauty%7CDay Spas': {name:'spa' , label: 'Spa', icon: 'map-icon-spa',unicode: "\ue88e", color: '#A23B72' },
    'Beauty%7CMassage': {name:'spa' , label: 'Spa', icon: 'map-icon-spa' ,unicode: "\ue88e", color:'#A23B72' },
    'Arts%20%26%20Entertainment%7CStadiums%20%26%20Arenas': {name:'stadium' , label: 'Stadium', icon: 'map-icon-stadium',unicode:"\ue892" , color:'#F18F01'  },
    'Local Services%7CSelf Storage': {name:'storage' , label: 'Storage', icon: 'map-icon-storage',unicode: "\ue893" , color:'#C73E1D'  },
    'Shopping%7CFashion%7CShoe Stores': {name:'shoe_store' , label: 'Shoe Store', icon: 'map-icon-store',unicode: "\ue894", color: '#3B1F2B' },
    // 'Cafe': {name:'store' , label: 'Store', icon: 'map-icon-store',unicode:"\ue894" , color: '#D90368' },
    // 'Cafe': {name:'subway_station' , label: 'Subway Station', icon: ''map-icon-subway-station',unicode:"\ue895" , color:'#2E294E'  },
    'Food%7CGrocery': {name:'supermarket' , label: 'Supermarket', icon: 'map-icon-grocery-or-supermarket' ,unicode:"\ue843" , color: '#448CE7'},
    'Religious Organizations%7CSynagogues': {name:'synagogue' , label: 'Synagogue', icon: 'map-icon-synagogue' ,unicode: "\ue898", color: '#0B0C0E'},
    // 'Cafe': {name:'taxi_stand' , label: 'Taxi Stand', icon: 'map-icon-taxi-stand' ,unicode:"\ue89a" , color: '#EC0B43'},
    'Travel%7CTrain Stations': {name:'train_station' , label: 'Train Station', icon: 'map-icon-train-station',unicode:"\ue89d" , color: '#58355E' },
    // 'Cafe': {name:'transit_station' , label: 'Transit Station', icon: 'map-icon-transit-station' ,unicode:"\ue89e" , color:'#06158D' },
    'Travel%7CTravel Services': {name:'travel_agency' , label: 'Travel Agency', icon: 'map-icon-travel-agency',unicode:"\ue89f" , color:'#39DB1D'  },
    'Pets%7CVeterinarians': {name:'veterinary_care' , label: 'Veterinary Care', icon: 'map-icon-veterinary-care' ,unicode:"\ue8a2" , color: '#06158D'},
    'Arts%20%26%20Entertainment%7CZoos': {name:'zoo' , label: 'Zoo', icon: 'map-icon-zoo',unicode: "\ue8aa", color: '#F50A0A' },
  };  
  export default categoryMappings01;