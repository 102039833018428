import React, { useEffect, useState, useContext } from 'react';
import { Context } from '../../App';
import { useRef } from 'react';
import { GetBoundaryData, imageToBase64, jsonData, getPolygonStylesFromClass } from '../../Util/helper';
// import PointsOfInterest from './pointsOfInterest';
import { GetBoundaries } from '../../Services/area-map'
import POI from './poi';

export default function CommunityMap({ jsonSourceUrl }) {

  const { mapLoaded, setMapLoaded, mapImageBase64, setMapImageBase64, viewMode, setViewMode, centerLat, setCenterLat, centerLng, setCenterLng, setCoords, bounds, setBounds, coords } = useContext(Context);
  const [fullscreen, setFullscreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const mapRef = useRef(null);

  useEffect(() => {
    setViewMode('map');
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    imageToBase64('https://avm-service-stage.idcrealestate.com/assets/images/areamap.png', (base64String) => {
      setMapImageBase64(base64String);
    });
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [window._isMapLoaded]);


  useEffect(() => {
    setMapLoaded(false);
    const handleClick = (event) => {
      if (event.target && event.target.id === 'map-container') {
        if (centerLat !== null && centerLng !== null) {
          loadMap();
        }
        document.querySelector('.pin').style.display = 'none';
        document.getElementById("map-container").classList.add("clicked");
      }
    };

    const mapContainer = document.getElementById('map-container');
    if (!mapLoaded) {
      mapContainer.addEventListener('click', handleClick);
    } else {
      setMapLoaded(true)
      if (!mapRef.current) {
        loadMap();
      }
    }

    const handleViewModeChange = (newViewMode, mapTypeId) => {
      setViewMode(newViewMode);
      setMapLoaded(true)
      if (mapRef.current) {
        mapRef.current.setMapTypeId(mapTypeId);
        setMapLoaded(true)
        document.getElementById("map-container").classList.add("clicked");
      }
    };

    document.getElementById('satelliteview').addEventListener('click', () => handleViewModeChange('satellite', 'satellite'));
    document.getElementById('mapview').addEventListener('click', () => handleViewModeChange('map', 'roadmap'));

    return (() => {
      mapContainer.removeEventListener('click', handleClick);

    })
  }, [viewMode, centerLat, centerLng])


  const loadMap = () => {

    if (!centerLat || !centerLng || !bounds) return;

    const location = { lat: centerLat, lng: centerLng };
    const mapOptions = {
      center: location,
      styles: [
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        { featureType: "transit", elementType: "labels.icon", stylers: [{ visibility: "off" }] }
      ],
      disableDefaultUI: true,
    };

    const map = new google.maps.Map(document.getElementById("community-map"), mapOptions)
    mapRef.current = map;

    coords.forEach(polygon => {
      const boundaryCoords = polygon.Boundary.flatMap(coordsArray => {
        if (Array.isArray(coordsArray)) {
          return coordsArray.map(coord => {
            const lat = coord[1];
            const lng = coord[0];
            return new google.maps.LatLng(lat, lng);
          });
        } else {
          const lat = coordsArray.lat;
          const lng = coordsArray.lng;
          return [new google.maps.LatLng(lat, lng)];
        }
      }).filter(coord => coord !== null);

      boundaryCoords.forEach(coord => bounds.extend(coord));

      const boundaryStyleClass = 'area-map-boundary';
      const boundaryStyle = getPolygonStylesFromClass(boundaryStyleClass);

      const boundaryHoverStyleClass = 'area-map-boundary-hover';
      const boundaryHoverStyle = getPolygonStylesFromClass(boundaryHoverStyleClass);

      const polygonObject = new google.maps.Polygon({
        paths: boundaryCoords,
        fillColor: boundaryStyle.fillColor,
        fillOpacity: boundaryStyle.fillOpacity,
        strokeColor: boundaryStyle.strokeColor,
        strokeOpacity: boundaryStyle.strokeOpacity,
        strokeWeight: boundaryStyle.strokeWeight,
        name: polygon.Name,
        url: polygon.Detailurl
      });

      const infowindow = new google.maps.InfoWindow()

      google.maps.event.addListener(polygonObject, 'mouseover', function (e) {
        infowindow.setContent(`<div><a href="${polygonObject.url}" target="_blank">${polygonObject.name}</a></div>`);
        infowindow.setPosition(e.latLng);
        infowindow.open(mapRef.current);
        polygonObject.setOptions({ fillColor: boundaryHoverStyle.fillColor });
      });
      google.maps.event.addListener(polygonObject, 'mousemove', function (e) {
        infowindow.setPosition(e.latLng);
      });

      google.maps.event.addListener(polygonObject, 'mouseout', function () { infowindow.close(); polygonObject.setOptions({ fillColor: boundaryStyle.fillColor }); });

      google.maps.event.addListener(infowindow, 'domready', function () {
        const closeBtn = document.querySelector('.gm-ui-hover-effect');
        closeBtn.style.display = 'none';
      });

      polygonObject.setMap(map)
    });

    map.fitBounds(bounds, 20);

    document.getElementById('zoom_in').addEventListener('click', () => {
      let currentZoom = map.getZoom();
      if (currentZoom < 20) map.setZoom(++currentZoom);
    });

    document.getElementById('zoom_out').addEventListener('click', () => {
      let currentZoom = map.getZoom();
      if (currentZoom > 5) map.setZoom(--currentZoom);
    });

    document.getElementById('fullscreen').addEventListener('click', () => {
      const mapContainer = document.getElementById('map-container');
      if (!document.fullscreenElement) {
        mapContainer.requestFullscreen();
        setFullscreen(true);
      } else {
        document.exitFullscreen();
        setFullscreen(false)
      }
    });

    map.setMapTypeId(viewMode === 'satellite' ? 'satellite' : 'roadmap');
    document.querySelector('.pin').style.display = 'none';
    document.getElementById("map-container").classList.add("clicked");
    setMapLoaded(true);
  }

  const handleFullscreenChange = (event) => {
    if (!document.fullscreenElement) {
      setFullscreen(false);
    }
  };

  return (<>
    <div className='m-b-16 s-br-2 s-d-f s-p-r map-view-tabs tabs2'>
      <a id='mapview' className={`s-c-p p-8 f-s-3 f-w-6 s-d-f s-f-jc-c s-f-ai-c s-p-r ${viewMode === 'map' ? 'c-6 active-label' : 'c-2'}`}><i className='aga-map'></i><b className='f-w-6'>Map View</b></a>
      <a id="satelliteview" className={`s-c-p p-8 f-s-3 f-w-6 s-d-f s-f-jc-c s-f-ai-c s-p-r ${viewMode === 'satellite' ? 'c-6 active-label' : 'c-2'}`}><i className='aga-satellite'></i><b className='f-w-6'>Satellite View</b></a>
    </div>
    <div className={`p-t-4 s-br-2 map-section community-map ${viewMode !== 'pointsOfInterest' ? 's-c-p' : ''}`}>
      <div className='s-p-r' id='map-fullscreen'>
        <div id='map-container' className={`s-br-2 s-p-r map-container ${!mapLoaded ? '' : 'clicked'}`} style={{ display: viewMode !== 'pointsOfInterest' ? '' : 'none' }} >
          <div id='community-map' className={`s-w-100 s-h-100 s-br-2 s-o-h dynamic-map ${fullscreen ? 'fullscreen-map' : ''} `} style={{ backgroundImage: viewMode === 'map' || !mapLoaded ? `url(${mapImageBase64})` : '' }}></div>
          <div className="pin f-s-3 f-w-6 s-ta-c" data-text="CLICK TO VIEW MAP"></div>
          <div id='map-action' className="s-p-a map_action" style={{ display: !mapLoaded ? 'none' : '' }}>
            <a aria-label="Map Zoom In" id="zoom_in" className="s-br-50 map-btn"><i className="aga-add" /><span className="s-br-2 p-4 tooltiptext">Zoom In</span></a>
            <a aria-label="Map Zoom Out" id="zoom_out" className="s-br-50 map-btn"><i className="aga-remove" /><span className="s-br-2 p-4 tooltiptext">Zoom Out</span></a>
            <a aria-label="Map Fullscreen" id="fullscreen" className="s-br-50 map-btn"><i className={fullscreen ? 'aga-fullscreen-exit' : 'aga-fullscreen'} /><span className="s-br-2 p-4 tooltiptext">Full Screen</span></a>
          </div>
          {centerLat !== null && centerLng !== null && mapLoaded && <POI latitude={centerLat} longitude={centerLng} mapRef={mapRef} viewMode={viewMode} mapLoaded={mapLoaded} limit={300} circleRadius={20} fullscreen={fullscreen} />}
        </div>
      </div>
    </div>
  </>)
}