import React, { useState, useEffect, useCallback } from 'react'
import { searchMultiplePointsOfInterest, searchMultiplePointsOfInterest01 } from '../../Services/area-map'
import categoryMappings from '../../Util/CategoryMappings';


export default function PointsOfInterest({ polygons, latitude, longitude, mapRef, viewMode, mapLoaded, fullscreen, bounds, circleRadius = 5, limit = 500 }) {

  const [matchedPOI, setMatchedPOI] = useState({});
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryArrays, setCategoryArrays] = useState({});
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [categoriesPerRow, setCategoriesPerRow] = useState(0);
  const [clickedCategories, setClickedCategories] = useState([]);
  const [placedMarkers, setPlacedMarkers] = useState({});
  const [activeCategories, setActiveCategories] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noPOIsFound, setNoPOIsFound] = useState(''); 

  let filteredPOI = {};

  const CategoryOrder = ['AIRPORT', 'BAKERY', 'BANK', 'CAFE', 'CHURCH', 'MOSQUE', 'SYNAGOGUE','COFFEE SHOP', 'DELI', 'GOLF', 'GROCERY', 'HOSPITAL', 'LIBRARY', 'LODGING', 'PARK', 
    'PARKING', 'POOL', 'RESTAURANT', 'SALON', 'SCHOOL', 'SHOPPING', 'SPA', 'TENNIS COURT']

  // useEffect(() => {
  //   fecthPOIAPI()
  // }, [latitude, longitude, circleRadius, limit])

  useEffect(() => {
    if (viewMode !== 'street' && !apiCalled) {
      fetchPOIAPI();
      setApiCalled(true);
    }
  }, [latitude, longitude, circleRadius, limit, viewMode, apiCalled]);


  useEffect(() => {
    const filteredPOI = filterPOI(categoryMappings);
    setMatchedPOI(filteredPOI);
  }, [categoryArrays, filterPOI]);


  useEffect(() => {
    const updateCategoriesPerRow = () => {
      setTimeout(() => {
        const poiContextElement = document.querySelector('.am-poi-context');
        const categoryElements = document.querySelectorAll('.map-icon');
        if (poiContextElement && categoryElements.length > 0) {
          let totalWidth = 0;
          let count = 0;
          const poiContextWidth = poiContextElement.clientWidth;

          categoryElements.forEach(categoryElement => {
            const categoryWidth = categoryElement.clientWidth ;

            if ((totalWidth + categoryWidth) <= poiContextWidth) {
              totalWidth += categoryWidth;
              count++;
            } else {
              return;
            }
          });
          setCategoriesPerRow(count);
        }
      }, 100);
    };

    updateCategoriesPerRow();
    window.addEventListener('resize', updateCategoriesPerRow);

    return () => window.removeEventListener('resize', updateCategoriesPerRow);
  }, [categoryArrays, viewMode, mapLoaded]);


  const fetchPOIAPI = async () => {
    setIsLoading(true);
    try {
      const categoriesArray = Object.keys(categoryMappings);
      const POIData = await searchMultiplePointsOfInterest(latitude, longitude, categoriesArray, limit, circleRadius=5);  
      const nonEmptyPOIData = Object.keys(POIData).reduce((acc, category) => {
          if (POIData[category] && POIData[category].length > 0) {
            acc[category] = POIData[category];
          }
          return acc;
        }, {});
    
        if (Object.keys(nonEmptyPOIData).length > 0) {
          setCategoryArrays(nonEmptyPOIData);
        } else {
          setCategoryArrays({});
        }

    } catch (error) { error }
    setIsLoading(false);
  }


  // const capitalizeCategory = (category) => {
  //   if (!category) return '';
  //   return category.charAt(0).toUpperCase() + category.slice(1).toLowerCase();
  // }

  const capitalizeCategory = (category) => {
    if (!category) return '';
    return category
      .split(' ') .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  const filterPOI = useCallback((categoryMappings) => {
    Object.keys(categoryArrays).forEach((category) => {
      const formattedCategory = capitalizeCategory(category);
      Object.keys(categoryMappings).forEach((key) => {
        if (categoryMappings[key].label === formattedCategory) {
          filteredPOI[category] = {
          category: key,
          ...categoryMappings[key],
        };
      }
    });
    });
    return filteredPOI;
  }, [categoryArrays]);


  const findPlaces = useCallback((category, iconUrl, color) => {
    const normalizedCategory = category.toUpperCase();
      if (placedMarkers[category]) {
      placedMarkers[category].forEach(marker => marker.setMap(null));
      setPlacedMarkers(prevMarkers => ({
        ...prevMarkers,
        [category]: null
      }));
      setActiveCategories(prev => prev.filter(cat => cat !== category));
    } else {
      if (categoryArrays.hasOwnProperty(normalizedCategory)) {
        const markers = categoryArrays[normalizedCategory].map((place) => {
          const { Latitude, Longitude, BusinessName, Street, Zip } = place;
          const address = `${Street}, ${Zip}`;
          
          return createMarker({
            Latitude,
            Longitude,
            BusinessName,
            address
          }, iconUrl, color);
        });
        setPlacedMarkers(prevMarkers => ({
          ...prevMarkers,
          [category]: markers
        }));
        setActiveCategories(prev => [...prev, category]);
      }
    }
  
    // Update clicked categories
    setClickedCategories(prevClickedCategories => 
      prevClickedCategories.includes(category) 
        ? prevClickedCategories.filter(cat => cat !== category)
        : [category, ...prevClickedCategories]
    );
  
  }, [clickedCategories, setClickedCategories, categoryArrays, createMarker, placedMarkers]);

  const createMarker = useCallback((place, iconUrl, color) => {
    const position = {
      lat: parseFloat(place.Latitude),
      lng: parseFloat(place.Longitude)
    };
    const marker = new google.maps.Marker({
      position: position,
      map: mapRef.current,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 0.6,
        strokeOpacity: 0,
        scale: 10,
      },
      label: {
        text: iconUrl,
        color: 'white',
        fontFamily: 'map-icons',
      },
    });

    // if (bounds) mapRef.current.fitBounds(bounds);

    const infowindow = new google.maps.InfoWindow({
      content: `<div class="s-d-f s-f-fd-c map-pop-up">
                  <span class="f-s-2 f-w-6 m-l-8">${place.BusinessName}</span>
                  <span class="f-s-1 f-w-5 m-l-8">${place.address}</span>
                </div>`
    });
    // marker.addListener('click', function () { mapRef.current.setZoom(14); mapRef.current.setCenter(marker.getPosition()); infowindow.open(mapRef.current, marker); });
    marker.addListener('click', function () { mapRef.current.setZoom(18); mapRef.current.setCenter(marker.getPosition()); infowindow.open(mapRef.current, marker); });
    marker.addListener('mouseover', function () { infowindow.open(mapRef.current, marker); marker.setOptions({ cursor: 'pointer' }) });
    marker.addListener('mouseout', function () { infowindow.close(); });

    google.maps.event.addListener(infowindow, 'domready', function () {
      const closeBtn = document.querySelector('.gm-ui-hover-effect');
      closeBtn.style.display = 'none';
    });

    return marker;
  }, [mapRef]);

  // const calculateCategoriesPerRow = () => {
  //   const poiContextElement = document.querySelector('.am-poi-context');
  //   const categoryElements = document.querySelectorAll('.map-icon');
  //   if (poiContextElement && categoryElements.length > 0) {
  //     let totalWidth = 0;
  //     let count = 0;
  //     const poiContextWidth = poiContextElement.clientWidth;

  //     categoryElements.forEach(categoryElement => {
  //       const categoryWidth = categoryElement.clientWidth + 10;

  //       if ((totalWidth + categoryWidth) <= poiContextWidth) {
  //         totalWidth += categoryWidth;
  //         count++;
  //       } else {
  //         return;
  //       }
  //     });
  //     return count;
  //   }
  // };

  const toggleShowAllCategories = () => { setShowAllCategories(prev => !prev) };

  return (<>
    {isLoading && viewMode !== 'street' && (
      <div className={`s-d-f s-f-jc-c s-f-ai-c ${viewMode === 'pointsOfInterest' ? '' : 'p-20 poi-loader-container'}`}>
        <div className={`s-br-50 direction-loader ${viewMode === 'pointsOfInterest' ? '' : 'poi-loader'}`} />
      </div>
    )}

    {Object.keys(categoryArrays).length > 0 && <>
      {viewMode !== 'street' && mapLoaded &&
        <div className='p-12 s-br-2 icon-container'>
          <div className={`s-d-f s-f-jc-fs s-f-ai-c s-f-fw-w am-poi-context ${showAllCategories ? '' : 'poi-context-active'}`}>
          {Object.keys(matchedPOI).sort((a, b) => {
              const indexA = CategoryOrder.indexOf(a);
              const indexB = CategoryOrder.indexOf(b);
              if (indexA === -1 && indexB === -1) {
                return a.localeCompare(b);
              } else if (indexA === -1) {
                return 1;
              } else if (indexB === -1) {
                return -1;
              } else {
                return indexA - indexB;
              }
            }).map((category, index) => (
              <a aria-label={`map-icon-${category}`} key={index} className={`c-2 s-td-n map-icon ${activeCategories.includes(matchedPOI[category].label) ? 'active-poi' : ''}`} onClick={(e) => findPlaces(matchedPOI[category].label, matchedPOI[category].unicode, matchedPOI[category].color)}>
                <span className={matchedPOI[category].icon} style={{ color: matchedPOI[category].color }}></span>
                <span  data-count={matchedPOI[category].icon.length} >{`${matchedPOI[category].label}`}</span>
              </a>
            ))}
          </div>
          {Object.keys(matchedPOI).length > categoriesPerRow && (categoriesPerRow !== 'undefined' || categoriesPerRow !== 0) &&(
            <a className='view-more' onClick={toggleShowAllCategories}><i className={`s-td-n ${showAllCategories ? 'aga-keyboard-arrow-down' : 'aga-keyboard-arrow-up'}`} style={{ fontSize: '22px' }}></i></a>
          )}
        </div>
      }
    </>}
  </>)
}
