import React, { useState, useEffect, useRef, useContext } from 'react'
import { toast } from 'react-toastify';
import { Context } from '../../App';
import Directions from './directions';

export default function RoutesData({ containerId, setAddress, selectedTravelMode, selectedDate }) {

  const { destinationAddress, startingAddress, setStartingAddress } = useContext(Context);
  const [drivingRoute, setDrivingRoute] = useState(null);
  const [transitRoute, setTransitRoute] = useState(null);
  const [walkingRoute, setWalkingRoute] = useState(null);
  const [cyclingRoute, setCyclingRoute] = useState(null);
  const [selectedTransitIndex, setSelectedTransitIndex] = useState(null);
  const [dateInIST, setDateInIST] = useState('');
  const [showDirectionsMap, setShowDirectionsMap] = useState(false);
  const [selectedDrivingIndex, setSelectedDrivingIndex] = useState(null);
  const [directionsData, setDirectionsData] = useState({ destinationAddress: '', startingAddress: '', routeSummary: '' })
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  let directionsServiceRef = useRef(null);

  const routesContainerRef = useRef(null);

  // const dateInIST = new Date(selectedDate);

  useEffect(() => {
    if (selectedDate && selectedDate !== new Date().toISOString().slice(0, 10)) {
      const newDateInIST = new Date(selectedDate);
      newDateInIST.setHours(24, 0, 0, 0);
      setDateInIST(newDateInIST)
    } else {
      setDateInIST(new Date());
    }
  }, [selectedDate]);

  const request = {
    origin: startingAddress,
    destination: destinationAddress,
    travelMode: selectedTravelMode,
    provideRouteAlternatives: true,
    transitOptions: {
      departureTime: dateInIST,
    },
  };


  const handleTransitRouteClick = (index) => {
    setSelectedTransitIndex(index === selectedTransitIndex ? null : index);
    if (routesContainerRef.current) {
      routesContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (window._isMapLoaded) {
    directionsServiceRef.current = new window.google.maps.DirectionsService();
  }

  const convert24to12 = (timeStr) => {
    if (!timeStr) return ''; 
  
    const [hour, minute] = timeStr.split(':').map(Number);
  
    if (isNaN(hour) || isNaN(minute)) return timeStr;
  
    const suffix = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minute.toString().padStart(2, '0')} ${suffix}`;
  };
  

  useEffect(() => {
    setTransitRoute(null);
    setDrivingRoute(null);
    setIsLoading(true);
    setErrorMessage(false);
    if (startingAddress) {
      directionsServiceRef.current.route(request, (result, status) => {
        setIsLoading(false);
        setErrorMessage(false);
        if (status === 'OK') {
          switch (selectedTravelMode) {
            case 'DRIVING':
              const drivingRoutes = result.routes.map(route => ({

                duration: route.legs[0].duration.text,
                distance: route.legs[0].distance.text,
                steps: route.legs[0].steps,
                summary: route.summary,
              }));
              setDrivingRoute(drivingRoutes);
              break;
            case 'TRANSIT':
              const transitRoutes = result.routes.map(route => {
                const transitLegs = route.legs.map(leg => {
                  const transitSteps = leg.steps.map(step => {
                    const transit = step.transit;

                    const formatTime = (time) => {
                      if (!time) return ''; 
                      if (time.includes('AM') || time.includes('PM')) {
                        return time; 
                      }
                      return convert24to12(time);
                    };
                    
                    const departureTimeIST = leg.departure_time?.value ?? '';
                    const adjustedDepartureTime = new Date(departureTimeIST.toLocaleString('en-US', { timeZone: leg.departure_time?.time_zone ?? '' }));
                    const adjustedDepartureDay = adjustedDepartureTime.toLocaleDateString('en-US', { weekday: 'long' });

                    const arrivalTimeIST = leg.arrival_time?.value ?? '';
                    const adjustedArrivalTime = new Date(arrivalTimeIST.toLocaleString('en-US', { timeZone: leg.arrival_time?.time_zone ?? '' }));
                    const adjustedArrivalDay = adjustedArrivalTime.toLocaleDateString('en-US', { weekday: 'long' });

                    return {
                      name: step.instructions,
                      departure:formatTime (transit?.departure_time?.text ?? ''),
                      arrival: formatTime(transit?.arrival_time?.text ?? ''),
                      duration: step.duration?.text ?? '',
                      distance: step.distance?.text ?? '',
                      mode: step.travel_mode,
                      arrivalStop: transit?.arrival_stop?.name ?? '',
                      vehicleType: transit?.line?.vehicle?.type ?? '',
                      departureStop: transit?.departure_stop?.name ?? '',
                      shortName: transit?.line?.short_name || transit?.line?.name || transit?.headsign || '',
                      departureTime:formatTime(leg.departure_time?.text ?? ''),
                      arrivalTime: formatTime(leg.arrival_time?.text ?? ''),
                      totalTime: leg.duration?.text ?? '',
                      departureday: leg.departure_time?.value ?? '',
                      arrivalday: leg.arrival_time?.value ?? '',
                      departureTimeZone: leg.departure_time?.time_zone ?? '',
                      arrivalTimeZone: leg.arrival_time?.time_zone ?? '',
                      departureDay: adjustedDepartureDay,
                      arrivalDay:adjustedArrivalDay,
                    };
                  });
                  return transitSteps;
                });
                return transitLegs;
              });
              const filteredTransitRoutes = transitRoutes.filter(route => {
                const departureDay = route[0][0].departureday;
                if (departureDay)
                  return departureDay.toISOString().slice(0, 10) === dateInIST.toISOString().slice(0, 10);
              });
              // if (filteredTransitRoutes.length === 0) {
              // toast(`No transit options are available for the destination on ${selectedDate}.`, { position: toast.POSITION.BOTTOM_CENTER, className: 'areamap-info', autoClose: false, hideProgressBar: true, closeOnClick: true, pauseOnHover: false });
              // setAddress('')
              // setStartingAddress('')
              // }
              setTransitRoute(filteredTransitRoutes);
              break;
            case 'WALKING':
              const walkingRoutes = result.routes.map(route => ({
                duration: route.legs[0].duration.text,
                distance: route.legs[0].distance.text,
                summary: route.summary,
              }));
              setWalkingRoute(walkingRoutes);
              break;
            case 'BICYCLING':
              const cyclingRoutes = result.routes.map(route => ({
                duration: route.legs[0].duration.text,
                distance: route.legs[0].distance.text,
                summary: route.summary,
              }));
              setCyclingRoute(cyclingRoutes);
              break;
            default:
              break;
          }
        } else {
          // let selectedTravelModeLowerCase = selectedTravelMode ? selectedTravelMode.toLowerCase() : null;
          // toast(`No ${selectedTravelModeLowerCase} routes found between the starting and destination addresses.`, { position: toast.POSITION.BOTTOM_CENTER, className: 'areamap-info', autoClose: false, hideProgressBar: true, closeOnClick: true, pauseOnHover: false });
          // setAddress('');
          // setStartingAddress('');
          setErrorMessage(true);
        }
      });
    }
  }, [startingAddress, selectedTravelMode, dateInIST]);


  const toggleDirectionsMap = () => {
    setShowDirectionsMap(!showDirectionsMap);
  };

  useEffect(() => {
    if (startingAddress === '') {
      setErrorMessage(false);
    }
  }, [selectedTravelMode]);


  const handleGetDirections = (routeSummary) => {
    directionsServiceRef.current.route(request, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirectionsData({ destinationAddress: destinationAddress, startingAddress: startingAddress, routeSummary: routeSummary });
        setSelectedDrivingIndex(routeSummary === selectedDrivingIndex ? null : routeSummary);
        setShowDirectionsMap(true);
        if (routesContainerRef.current) {
          routesContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else {
        toast('No route found between the starting to destination addresses.', { position: toast.POSITION.BOTTOM_CENTER, className: 'areamap-info', autoClose: false, hideProgressBar: true, closeOnClick: true, pauseOnHover: false });
        setShowDirectionsMap(false);
      }
    });
  };


  const formatDate = (selectedDate) => {
    const dateObj = new Date(selectedDate);
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getUTCDate().toString().padStart(2, '0');
    const year = dateObj.getUTCFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };


  const travelMode = selectedTravelMode === 'DRIVING' ? 'driving' : ' mass transit ';

  return (
    <div ref={routesContainerRef} className={`p-t-20 s-d-n routes-data-section ${selectedTravelMode === 'DRIVING' ? 'driving-routes' : 'transit-routes'}`}>
      {isLoading && startingAddress !== '' && (<div className="s-d-f s-f-jc-c s-f-ai-c s-w-100 p-b-20 loader-container"><div className="s-br-50 direction-loader" /></div>)}
      {errorMessage === true && (<span className='f-s-3 f-w-4 p-20 c-4'>No {travelMode} routes found between the starting and destination addresses.</span>)}
      {selectedTravelMode === 'DRIVING' && (<>
        {drivingRoute && drivingRoute.length > 0 && (<>
          <h4 className='m-tb-12 f-s-6 f-w-6'>Driving Routes</h4>
          <div className="s-d-f s-f-fw-w p-t-20 driving-routes-container">
            {drivingRoute.map((route, index) => {
              const distanceText = route.distance.replace('mi', 'miles');
              return (
                <div className='s-w-100 s-b-w driving-route-section' key={index}>
                  <div className={`p-tb-16 p-lr-12 s-p-r s-c-p get-direction ${selectedDrivingIndex === route.summary ? 'active-direction' : ''}`} onClick={() => handleGetDirections(route.summary)}>
                    <div className='s-d-f s-f-jc-sb s-f-ai-c driving-routes-details'>
                      <span className='p-r-8 f-s-3 f-w-6 route'>via {route.summary}</span>
                      <div className='s-d-f s-f-fd-c route-duration'>
                        <span className='p-l-8 f-s-3 f-w-6 m-b-4 s-ta-r duration'>{route.duration}</span>
                        <span className='f-s-2 s-ta-r total-dist'>{distanceText}</span>
                      </div>
                    </div>
                  </div>
                  {selectedDrivingIndex === route.summary && showDirectionsMap && (
                    <Directions destinationAddress={directionsData.destinationAddress} startingAddress={directionsData.startingAddress} routeSummary={directionsData.routeSummary} />
                  )}
                </div>)
            })}
          </div>
        </>)}
      </>)}
      {selectedTravelMode === 'TRANSIT' && (<>
        {transitRoute && transitRoute.length > 0 && (<>
          <h4 className='m-tb-12 f-s-6 f-w-6'>Mass Transit Options</h4>
          <div className='transit-routes-container'>
            {transitRoute.map((route, routeIndex) => (<>
              <div className='s-c-p transit-routes-details' key={routeIndex}>
                {route.map((leg, legIndex) => (
                  <div className='transit-outer' key={legIndex} >
                    <div id={`active-route-${containerId}`} className={`p-tb-16 p-lr-12 s-p-r transit-main ${selectedTransitIndex === routeIndex ? 'active-route' : ''}`} onClick={() => handleTransitRouteClick(routeIndex)}>
                      <div className='s-d-f s-f-jc-sb f-s-3 f-w-6 p-b-8 day-time'>
                        <span>
                          {(route[0][0].departureTime)} ({route[0][0].departureday && route[0][0].departureDay}) - {(route[0][0].arrivalTime)} ({route[0][0].arrivalday && route[0][0].arrivalDay})
                        </span>
                        <span className='s-ta-r'>
                          {route[0][0].totalTime}
                        </span>
                      </div>
                      <div className='s-d-f s-f-fd-r s-f-ai-c s-f-fw-w' style={{ width: '90%' }}>
                        {leg.map((step, stepIndex) => (
                          <span className='s-d-f' key={stepIndex}>
                            {stepIndex > 0 && <i className='aga-keyboard-arrow-right' />}
                            {step.shortName !== '' ? <span className='s-d-f s-f-ai-c f-w-4' style={{ fontSize: '15px', lineHeight: '1.5' }}><i className={step.vehicleType === 'HEAVY_RAIL' ? 'aga-transit' : 'aga-directionsbus'} style={{ ...(step.vehicleType === 'HEAVY_RAIL' ? { fontSize: '22px' } : { fontSize: '20px' }) }} /> {step.shortName}</span> : <i className='aga-walk' style={{ fontSize: '20px' }} />}
                          </span>
                        ))}
                      </div>
                    </div>
                    {selectedTransitIndex === routeIndex && (
                      <div className='p-lr-100 p-tb-30 s-b-g transit-route-section2'>
                        <div className='transit-detailed-data'>
                          {route.map((leg, legIndex) => (
                            <div className='details-data' key={legIndex}>
                              <div className='s-d-f s-f-ai-c p-tb-4 f-w-6 substep'>
                                <span className='s-d-f s-f-fd-c f-s-2 f-w-5 s-ta-r p-r-8 s-mw-70'>{(route[0][0].departureTime)}</span>
                                <span className='s-ws-nw s-o-h s-to-e s-d-b s-w-100 p-l-20 s-p-r f-s-3 f-w-4 starting-address'><i className="f-s-3 f-w-6 s-l-0 s-p-a aga-radio-button-unchecked"></i>{startingAddress}</span>
                              </div>
                              {leg[0]?.departureStop !== '' &&
                                <div className='s-d-f s-f-ai-c p-l-60 s-p-r im-h-100 transit-modes'>
                                  <i className='f-s-5 s-p-a aga-walk' />
                                  <div className='transit-walk s-h-100 s-p-a m-l-4 walk-mode'></div>
                                </div>
                              }
                              {leg.map((step, stepIndex) => (
                                <div className='s-d-f s-f-fd-c' key={stepIndex}>
                                  {step.departureStop ?
                                    <div className='s-d-f s-f-ai-c substep'>
                                      <div className='s-d-f s-f-fd-c f-s-2 f-w-5 s-ta-r p-r-8 s-mw-70' style={{ lineHeight: '16px' }}>
                                        {step.arrival !== leg[stepIndex + 1].departure  && (<span>{(leg[stepIndex -1]?.arrival)}</span>)}
                                         <span>{(step.departure)}</span>
                                      </div>
                                      <span className='s-ws-nw s-o-h s-to-e s-d-b s-w-100 p-l-20 s-p-r f-s-3'><i className="f-s-3 f-w-6 s-l-0 s-p-a aga-radio-button-unchecked"></i>{step.departureStop}</span>
                                    </div> : ''}
                                  <div className='s-d-f s-f-ai-c p-l-60 s-p-r im-h-100 transit-modes'>
                                    {step.shortName !== '' ? <i className={`f-s-5 s-p-a ${step.vehicleType === 'HEAVY_RAIL' ? 'aga-transit' : 'aga-directionsbus'}`} /> : <i className='f-s-5 s-p-a aga-walk' />}
                                    <div className={`transit-walk s-h-100 s-p-a m-l-4 ${step.mode === 'TRANSIT' ? 'ride-mode' : 'walk-mode'}`}></div>
                                    <div className='p-l-60 s-d-f s-f-fd-c f-s-2 f-w-3 dist-time'>
                                      <span>{step.distance.replace('mi', 'miles').replace('ft', 'feet')}</span>
                                      <span>{step.duration}</span>
                                    </div>
                                  </div>
                                  {step.arrivalStop && (<div className='s-d-f s-f-ai-c substep'>
                                    {step.arrivalStop !== leg[stepIndex + 1].departureStop && (<><span className='f-s-2 s-ta-r p-r-8 f-w-5 s-mw-70'>{(step.arrival)}</span>
                                      <span className='s-ws-nw s-o-h s-to-e s-d-b s-w-100 p-l-20 s-p-r f-s-3'><i className="f-s-3 f-w-6 s-l-0 s-p-a aga-radio-button-unchecked" />{step.arrivalStop}</span></>)}
                                  </div>)}
                                </div>
                              ))}
                              <div className='s-d-f s-f-ai-c p-tb-4 f-w-6 substep'>
                                <span className='f-s-2 s-ta-r p-r-8 f-w-5 s-mw-70'>{(route[0][0].arrivalTime)}</span>
                                <span className='s-ws-nw s-o-h s-to-e s-d-b s-w-100 p-l-20 s-p-r f-s-3 destination-address'><i className="f-s-3 f-w-6 s-l-0 s-p-a aga-destination" />{destinationAddress}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>))}
          </div>
        </>)}
        {transitRoute && transitRoute.length === 0 && (
          <span className='f-s-3 f-w-4 p-20 c-4'>No Mass transit options are available for the destination on {formatDate(selectedDate)}</span>
        )}
      </>)}
    </div>
  )
}